import React, { useState, useEffect }  from 'react';
import { Form, Layout, DatePicker, Button, Space, notification } from 'antd';
import { Table } from "ant-table-extensions";
import { FilterOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment-timezone';

import 'antd/dist/antd.css';
import 'antd-button-color/dist/css/style.css';

const { Content } = Layout;
const { RangePicker } = DatePicker;

const AppBigDataReport = () => {
  const [form] = Form.useForm();
  const [state, setstate] = useState([]);
  const [value, setValue] = useState([moment.tz('Europe/Istanbul'), moment.tz('Europe/Istanbul')]);

  useEffect(async () => {
    getData(moment.tz('Europe/Istanbul'), moment.tz('Europe/Istanbul'));
  }, []);

  const openNotification = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
      placement: "topRight"
    });
  };

  const getData = async (startDate, dueDate) => {
    axios.post(`/bulk-data-list`, {
        startDate: startDate,
        dueDate: dueDate
      }).then(
      res => {
        setstate(
          res.data.data.map(row => ({
            key: row.CITY,
            CityCode: row.CITY,
            CityName: row.CITY_NAME,
            TotalContact: row.totalContact,
            SuccessContact: row.successContact,
            FailContact: row.failContact,
            SuccessIys: row.successIys,
            FailIys: row.failIys,
            UncheckedIys: row.uncheckedIys,
            LeadContact: row.leadContact
          }))
        );
      }
    );
  };

  const columns = [
    {
      title: "Kod",
      dataIndex: "CityCode"
    },
    {
      title: "İl",
      dataIndex: "CityName"
    },
    {
      title: "Toplam Veri",
      dataIndex: "TotalContact"
    },
    {
      title: "Başarılı Veri",
      dataIndex: "SuccessContact"
    },
    {
      title: "Başarısız Veri",
      dataIndex: "FailContact"
    },
    {
      title: "IYS İzinli Veri",
      dataIndex: "SuccessIys"
    },
    {
      title: "IYS İzinsiz Veri",
      dataIndex: "FailIys"
    },
    {
      title: "IYS Kontrol Edilmemiş Veri",
      dataIndex: "UncheckedIys"
    },
    {
      title: "Toplam Lead",
      dataIndex: "LeadContact"
    },
  ];

  const Filter = async () => {
    if(!value[0] || !value[1]) return openNotification("warning", "Filter Error!", "Lütfen tarih aralığı seçin.");
    
    await getData(value[0], value[1]);
  }
  
  const SetDate = async (date) => {
    if(date) {
      setValue([date[0], date[1]]);
    } else {
      setValue([null, null]);
    }
  }

  return (
    <Content>
      <div className="child-header" align="center">
        <Space>
          <RangePicker 
            picker="day" 
            defaultValue={[moment(moment.tz('Europe/Istanbul').format('YYYY-MM-DD')), moment(moment.tz('Europe/Istanbul').format('YYYY-MM-DD'))]}
            onChange={(date) => SetDate(date)}  
            />
          <Button 
            type="primary" 
            icon={<FilterOutlined />} 
            onClick={() => Filter()}
          > Filter 
          </Button>
        </Space>
      </div>
      
      <div>
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={state}
            columns={columns}
            rowClassName="editable-row"
            exportable
            exportableProps={{ 
              showColumnPicker: true, 
              fileName: "Buyuk-Veri-Rapor" ,
              btnProps: {
                type: "success",
                icon: <FileExcelOutlined />,
                children: <span>Export to CSV</span>,
              },
            }}
            searchable
            searchableProps={{
              inputProps: {
                placeholder: "Search this table...",
                prefix: <SearchOutlined />,
              },
            }}
          />
        </Form>
      </div>
    </Content> 
  );
};

export default AppBigDataReport;