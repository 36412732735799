import { Tabs } from 'antd';

import AppDailyDataPanel from '../components/home/daily-data-panel';
import AppSetQuota from '../components/home/set-quota';
import AppFileUpload from '../components/home/file-upload';
import AppBigDataReport from '../components/home/big-data-report';

const { TabPane } = Tabs;

function AppHome() {
    return (
        <div className="main block child-tab-bar">
            <Tabs defaultActiveKey="4">
                <TabPane tab="Büyük Veriye Ait Rapor" key="1">
                    <AppBigDataReport />
                </TabPane>
                <TabPane tab="Günlük Veri Takip Paneli" key="2">
                    <AppDailyDataPanel />
                </TabPane>
                <TabPane tab="Kota Tanımlama Paneli" key="3">
                    <AppSetQuota />
                </TabPane>
                <TabPane tab="Dosya Yükleme" key="4">
                    <AppFileUpload />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default AppHome;