import React, { useState, useEffect }  from 'react';
import { useCookies } from 'react-cookie';
import { InputNumber, Popconfirm, Form, Typography, Switch, Layout, notification } from 'antd';
import { Table } from "ant-table-extensions";
import { FileExcelOutlined, SearchOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment-timezone';
import 'moment/locale/tr';

const { Content } = Layout;

const openNotification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
    placement: "topRight"
  });
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  if(dataIndex == "Status") {
    var checked = record.Status == 1 ? true : false;
  }
  
  const inputNode = inputType === 'switch' ?  
    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} disabled={false} defaultChecked={checked} /> : 
    <InputNumber min={0} />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const AppSetQuota = () => {
  const [form] = Form.useForm();
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);
  const [editingKey, setEditingKey] = useState('');
  const [value, setValue] = useState();
  const [cookies, setCookie] = useCookies(['email']);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios.post(`/get-city-quotas`).then(
      res => {
        setloading(false);
        setstate(
          res.data.data.map(row => ({
            key: row.City,
            CityCode: row.City,
            CityName: row.CityName,
            Priority: row.Priority,
            0: row[moment.tz('Europe/Istanbul').format('YYYY-MM-DD')],
            1: row[moment.tz('Europe/Istanbul').add(1, 'days').format('YYYY-MM-DD')],
            2: row[moment.tz('Europe/Istanbul').add(2, 'days').format('YYYY-MM-DD')],
            3: row[moment.tz('Europe/Istanbul').add(3, 'days').format('YYYY-MM-DD')],
            4: row[moment.tz('Europe/Istanbul').add(4, 'days').format('YYYY-MM-DD')],
            5: row[moment.tz('Europe/Istanbul').add(5, 'days').format('YYYY-MM-DD')],
            6: row[moment.tz('Europe/Istanbul').add(6, 'days').format('YYYY-MM-DD')],
            Status: row.Status
          }))
        );
      }
    );
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    console.log("cancel");
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      var datas = await form.getFieldValue();
     
      datas.Status = datas.Status ? 1 : 0;
      datas.Email = cookies.email;
      
      for(var i = 0; i < state.length; i++) {
        if((state[i].CityCode !=  datas.CityCode) && (state[i].Priority ==  datas.Priority)) {
          return openNotification("warning", "Save Error!", "Öncelik değeri başka bir şehirdeki öncelik değeri ile eşleşmemelidir! Lütfen benzersiz bir öncelik değeri belirleyin.");
        }
      }
  
      const response = await axios({
        url: `/update-priority-quota`,
        method: "POST",
        data: {
          datas: datas
        }
      });
      
      const newData = [...state];
      
      const index = newData.findIndex((item) => key === item.key);
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      setstate(newData);
      setEditingKey('');
    } catch (err) {
      console.log(err.message);
    }
  };
  
  const columns = [
    {
      title: "İl Kodu",
      dataIndex: "CityCode",
    },
    {
      title: "İl",
      dataIndex: "CityName",
    },
    {
      title: "Öncelik",
      dataIndex: "Priority",
      editable: true,
    },
    {
      title: moment.tz('Europe/Istanbul').format('dddd'),
      dataIndex: "0",
      editable: false,
    },
    {
      title: moment.tz('Europe/Istanbul').add(1, 'days').format('dddd'),
      dataIndex: "1",
      editable: true,
    },
    {
      title:  moment.tz('Europe/Istanbul').add(2, 'days').format('dddd'),
      dataIndex: "2",
      editable: true,
    },
    {
      title:  moment.tz('Europe/Istanbul').add(3, 'days').format('dddd'),
      dataIndex: "3",
      editable: true,
    },
    {
      title:  moment.tz('Europe/Istanbul').add(4, 'days').format('dddd'),
      dataIndex: "4",
      editable: true,
    },
    {
      title:  moment.tz('Europe/Istanbul').add(5, 'days').format('dddd'),
      dataIndex: "5",
      editable: true,
    },
    {
      title:  moment.tz('Europe/Istanbul').add(6, 'days').format('dddd'),
      dataIndex: "6",
      editable: true,
    },
    {
      title: 'Statü',
      key: 'Status',
      dataIndex: 'Status',
      editable: true,
      render: Status => (
        <>
          <Switch 
            checkedChildren={<CheckOutlined />} 
            unCheckedChildren={<CloseOutlined />} 
            defaultChecked={Status == 1 ? true : false}
            disabled={true}
            key={1}
          /> 
        </>
      ),
    },
    {
      title: 'Düzenle',
      dataIndex: 'operation',
      width: '15%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="#"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'Status' ? 'switch' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Content>
      <div>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={state}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
            }}
            searchable
            searchableProps={{
              inputProps: {
                placeholder: "Search this table...",
                prefix: <SearchOutlined />,
              },
            }}
            exportable
            exportableProps={{ 
              showColumnPicker: true, 
              fileName: "Kota-Tanimlama" ,
              btnProps: {
                type: "success",
                icon: <FileExcelOutlined />,
                children: <span>Export to CSV</span>,
              },
            }}
          />
        </Form>
      </div>
    </Content>
  );
};

export default AppSetQuota;