import React, { useState, useEffect }  from 'react';
import { Form, Layout, Tag, Input } from 'antd';
import { Table } from "ant-table-extensions";
import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Content } = Layout;

const AppDailyDataPanel = () => {
  const [form] = Form.useForm();
  const [state, setstate] = useState([]);

  useEffect(async () => {
    getData();
  }, []);

  const data = {};
  const getData = async () => {
    axios.post(`/daily-data-list`).then(
      res => {
        setstate(
          res.data.data.map(row => ({
            key: row.CITY,
            CityCode: row.CITY,
            CityName: row.CITY_NAME,
            DailyQuota: row.dailyQuota,
            Dialed: row.dialed,
            tags: row.listStatus == 1 ? ['aktif'] : ['pasif'],
          }))
        );
      }
    );
  };

  const columns = [
    {
        title: "Kod",
        dataIndex: "CityCode"
    },
    {
        title: "İl",
        dataIndex: "CityName"
    },
    {
        title: "Günlük Kota",
        dataIndex: "DailyQuota"
    },
    {
        title: "Gerçekleşen",
        dataIndex: "Dialed"
    },
    {
        title: 'Kampanya Durumu',
        key: 'tags',
        dataIndex: 'tags',
        render: tags => (
          <>
            {tags.map(tag => {
              let color = tag == "aktif" ? 'green' : 'volcano';
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
    }
  ];

  return (
    <Content>
      <div>
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={state}
            columns={columns}
            rowClassName="editable-row"
            searchable
            searchableProps={{
              inputProps: {
                placeholder: "Search this table...",
                prefix: <SearchOutlined />,
              },
            }}
            exportable
            exportableProps={{ 
              showColumnPicker: true, 
              fileName: "Gunluk-Veri-Takip" ,
              btnProps: {
                type: "success",
                icon: <FileExcelOutlined />,
                children: <span>Export to CSV</span>,
              },
            }}
          />
        </Form>
      </div>    
    </Content> 
  );
};

export default AppDailyDataPanel;